const selectedPhotographs = [{
  title: 'Gold Creek Pond',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1564858763975-d99de59ee4bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2551&q=80',
  link: 'https://devin-huapaya.myshopify.com/products/gold-creek',
}, {
  title: 'Grey Mountains No. 1',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1554190907-8bce2d9036c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3578&q=80',
  link: 'https://devin-huapaya.myshopify.com/products/gray-mountain-no-01',
}, {
  title: 'Grey Mountains No. 2',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1554190907-8f48b2c972e3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80',
},{
  title: 'Blue Mountains',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1554190907-415f9faecb06?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
},{
  title: 'Mt Rainer',
  src: 'https://images.unsplash.com/photo-1565895637288-8e7b2ca61433?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
},{
  title: 'Fern',
  src: 'https://images.unsplash.com/photo-1565769966111-8bd50d3e7554?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjExMDk0fQ&auto=format&fit=crop&w=1234&q=80',
},{
  title: 'Leaves No. 1',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1565770154375-e7cc7f4dec27?ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80',
}, {
  title: 'Leaves No. 2',
  src: 'https://images.unsplash.com/photo-1554178057-fd191c001f36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80',
  unsplashUrl: 'https://unsplash.com/photos/d4U9keF34zk',
}, {
  title: 'Leaves No. 3',
  landscape: true,
  src: 'https://images.unsplash.com/photo-1565769930368-4b20dcc47c63?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
  unsplashUrl: 'https://unsplash.com/photos/m9aX2FdITw4',
// }, {
//   title: 'Leaves No. 4',
//   landscape: true,
//   src: 'https://images.unsplash.com/photo-1565769930368-4b20dcc47c63?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80',
}]

export default selectedPhotographs