import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useViewportScroll, } from "framer-motion";
import {quaternaryTitleFont} from '../../styles/fonts'
import rand from '../../utils/rand';
import styled, {css} from 'styled-components'
import space from '../../styles/space'
import {basicallyBlack} from '../../styles/colors'
import borderRadius from '../../styles/borderRadius'
import hexToRGB from '../../utils/hexToRGB'
import {fadeIn, extraLong} from '../../styles/animation'
import AnimateIn from './AnimateIn'
 
const StyledParallaxImage = styled(motion.img)`
  width: 100%;
  cursor: pointer;
  z-index: 2;

  ${({index}) => index % 2 ? (`
    margin-right: auto;
  `) : (`
    margin-left: auto;
  `)};

  max-width: ${({landscape}) => landscape ? '600px' : '350px'};
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);

  ${({width}) => width && (css`
    width: ${width}px;
    height: auto;
  `)}

  ${({br = true}) => br && borderRadius}
`

const StyledImageTitle = styled.h2`
  font-family: ${quaternaryTitleFont};
  font-weight: 400;
`

const Image = ({src, landscape, title, index}) => (
  <>
    <StyledParallaxImage src={src}  index={index} landscape={landscape} />
  
    <StyledImageTitle index={index}>
      {title}
    </StyledImageTitle>
  </>
)

const ParallaxImage = ({ link, ...rest}) => {
  return (
    <AnimateIn>
      {link ? (
        <a href={link} target="_blank">
          <Image {...rest} />
        </a>
      ) : (
        <Image {...rest} />
      )}
    </AnimateIn>
  );
};

export default ParallaxImage  