import React from 'react'
import styled from 'styled-components'
import selectedPhotos from '../config/selectedPhotos'
import Row from './styled/Row'
import Col from './styled/Col'
import ParallaxImage from './motion/ParallaxImage'
import space from '../styles/space'
import { breakpoints } from '../styles/breakpoints';
import LazyLoad from 'react-lazyload'

const StyledSelectedPhotographs = styled.div`
  padding-bottom: ${space.xl}px;
  padding-top: ${space.xl}px;
  transform: translateZ(1px);
  
  ${breakpoints.md} {
    padding-top: 0px;
  }
`

const StyledCol = styled(Col)`
  text-align: ${({index}) => index % 2 ? 'left': 'right'};
`

const SelectedPhotographs = () => {
  return (
    <StyledSelectedPhotographs>
      {selectedPhotos.map((photo, i) => {
          return (
            <Row key={`${photo.title} ${i*i}`} justify={(i % 2) ? 'flex-end' : 'flex-start'}>
              <StyledCol flex index={i}>
                {i === 0 ? (
                  <ParallaxImage key={i} {...photo} index={i}/>
                ) : (
                  <LazyLoad height={photo.landscape ? 400 :525} offset={100}>
                    <ParallaxImage key={i}  {...photo} index={i}/>
                  </LazyLoad>
                )}
              </StyledCol>
            </Row>
          )
        }
      )}
    </StyledSelectedPhotographs>
  )
}

export default SelectedPhotographs