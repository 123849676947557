import React from "react";
import Container from '../components/styled/Container'
import Row from '../components/styled/Row'
import styled from 'styled-components'
import {primaryTitleFont} from '../styles/fonts'
import breakpoints from '../styles/breakpoints'
import borderRadius from '../styles/borderRadius'
import {animateChildren, inAndUp, fadeIn, extraLong} from '../styles/animation'
import {basicallyBlack} from '../styles/colors'
import hexToRGB from '../utils/hexToRGB'

import {motion} from 'framer-motion'
import SelectedPhotographs from "../components/SelectedPhotographs";
import ParallaxPageTitle from '../components/motion/ParallaxPageTitle'
import projects from '../config/projects.config'
import space from '../styles/space'


const StyledImage = styled(motion.img).attrs({ 
  ...fadeIn, 
  ...extraLong 
})`
  width: 100%;
  z-index: 2;
  transform: translateZ(10px);
/* 
  ${({index}) => index && index % 2 ? (`
    margin-right: auto;
  `) : (`
    margin-left: auto;
  `)}; */

  max-width: ${({landscape}) => landscape ? '750px' : '525px'};
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
  ${({br = true}) => br && borderRadius}
`;

const StyledPageTitle = styled(ParallaxPageTitle)`
  z-index: 0;
`;

const RegularPage = () => {
  const project = projects[0];
  const {title, src} = project; 

  return (
    <Container>
      <StyledPageTitle>
          {title}
      </StyledPageTitle>
      
      <Row justify="center">
        <StyledImage src={src} landscape />
      </Row>
    </Container>
  );
}

export default RegularPage;
